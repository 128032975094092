 <template>
    <div class="newIn">
        <!-- <div class="newIn-title">
            <span>Home</span>/ <span>Sale</span>/
            <span>View All</span>
        </div> -->
        <div class="newIn-main">
            <div class="newIn-main-left" v-loading="loadLeft">
                <!-- <div class="left-time">
                    <div>
                        <h5>Daily New</h5>
                        <div v-for="(item, index) in timeList.timeDate" :key="index" @click="timeClick(index)">
                            <img :class="timeAtive == index ? 'ative' : ''" src="@/assets/images/newIn/√.png" />
                            <span>{{item}}</span>
                        </div>
                    </div>
                </div> -->
                <div class="btn" v-if="sizeText.length + colorList.length > 0" @click="claerSizeColor();screenClick();">
                    Clear All ({{(sizeText.length + colorList.length)}})
                </div>
                <el-collapse v-model="filterName">
                    <el-collapse-item title="Category" name="1">
                        <div class="Category">
                            <h5 :class="slectedId == treeId1 ? 'ative' : ''" @click="categoryClick(treeId1)">
                                {{ titleLeft }}
                            </h5>
                            <el-collapse v-model="activeNames2">
                                <el-collapse-item :title="item.category_name" :name="index" v-for="(item, index) in sortList" :key="index">
                                    <div class="item" v-for="(item1, index1) in item.child_list" :key="index1">
                                        <span :class="item1.category_id == slectedId ? 'ative' : ''"
                                            @click="categoryClick(item1.category_id)">
                                            {{ item1.category_name }}
                                        </span>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Size" name="2">
                        <div class="Size">
                            <div class="sizeText">
                                <div :class="sizeText.includes(item) ? 'ative' : ''" v-for="(item, index) in sizeTextList" :key="index" @click="sizeChange(item);screenClick();">
                                    <span>{{ item }}</span>
                                </div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Colors" name="3">
                        <div class="Colors">
                            <div class="colorText">
                                <div v-for="(item, index) in colorTextList" :key="index" @click="colorChange(item);screenClick();">
                                    <div class="color">
                                        <div :class="colorList.includes(item) ? 'outer-round ative' : 'outer-round'">
                                            <div :class="'round round-' + item"></div>
                                        </div>
                                        <span>{{ item }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
                <!-- <div>
                    <div>
                        <h5 :class="slectedId == 0 ? 'ative' : ''" @click="slectedId = 0;categoryClick(0)">{{titleLeft}}</h5>
                        <ul>
                            <li v-for="(item, index) in sortList" :key="index">
                                <span
                                    :class="item.category_id == slectedId ? 'ative' : ''"
                                    @click="
                                        slectedId = item.category_id;
                                        categoryClick(item.category_id)
                                    "
                                    >{{ item.category_name }}</span
                                >
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
            <div class="newIn-main-right">
                <div class="newIn-sort">
                    <div class="newIn-sort-title">
                        <h5>{{ titleRight }}</h5>
                        <span>{{ total }} Items</span>
                    </div>
                    <div class="newIn-sort-select">
                        <div :class="textAtive ? 'textAtive' : ''" @click="textAtive = !textAtive">
                            <div class="text">
                                <span>SORT BY</span>
                                <div class="img">
                                    <img v-if="!textAtive" src="@/assets/images/newIn/x.png" />
                                    <img v-if="textAtive" src="@/assets/images/newIn/s.png" />
                                </div>
                            </div>
                            <div class="sortText" v-if="textAtive">
                                <div :class="sortText == 0 ? 'ative' : ''" @click="sortClick(0)">
                                    <span>Recommended</span>
                                    <div v-if="sortText == 0" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                                </div>
                                <div :class="sortText == 1 ? 'ative' : ''" @click="sortClick(1)">
                                    <span>Most Popular</span>
                                    <div v-if="sortText == 1" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                                </div>
                                <div :class="sortText == 2 ? 'ative' : ''" @click="sortClick(2)">
                                    <span>New Arrivals</span>
                                    <div v-if="sortText == 2" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                                </div>
                                <div :class="sortText == 3 ? 'ative' : ''" @click="sortClick(3)">
                                    <span>Top Reviewed</span>
                                    <div v-if="sortText == 3" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                                </div>
                                <div :class="sortText == 4 ? 'ative' : ''" @click="sortClick(4)">
                                    <span>Price Low To High</span>
                                    <div v-if="sortText == 4" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                                </div>
                                <div :class="sortText == 5 ? 'ative' : ''" @click="sortClick(5)">
                                    <span>Price High To Low</span>
                                    <div v-if="sortText == 5" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                                </div>
                            </div>
                        </div>
                        <div :class="sizeAtive ? 'sizeAtive' : ''">
                            <div class="text" @click="sizeAtive = !sizeAtive">
                                <span>SIZE</span>
                                <div class="img">
                                    <img v-if="!sizeAtive" src="@/assets/images/newIn/x.png" />
                                    <img v-if="sizeAtive" src="@/assets/images/newIn/s.png" />
                                </div>
                            </div>
                            <div class="sizeText" v-if="sizeAtive">
                                <div :class="sizeText.includes(item) ? 'ative' : ''" v-for="(item, index) in sizeTextList" :key="index" @click="sizeChange(item);screenClick();">
                                    <span>{{ item }}</span>
                                    <div v-if="sizeText.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                                </div>
                            </div>
                        </div>
                        <div :class="colorAtive ? 'colorAtive' : ''">
                            <div class="text" @click="colorAtive = !colorAtive">
                                <span>COLOR</span>
                                <div class="img">
                                    <img v-if="!colorAtive" src="@/assets/images/newIn/x.png" />
                                    <img v-if="colorAtive" src="@/assets/images/newIn/s.png" />
                                </div>
                            </div>
                            <div class="colorText" v-if="colorAtive">
                                <div v-for="(item, index) in colorTextList" :key="index" @click="colorChange(item);screenClick();">
                                    <div class="color">
                                        <div class="outer-round">
                                            <div :class="'round round-' + item"></div>
                                        </div>
                                        <span>{{ item }}</span>
                                    </div>
                                    <div v-if="colorList.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                                </div>
                            </div>
                        </div>
                        <!-- <div>
                            <div class="text" @click="drawer = true">
                                <span>FILTER</span>
                                <div class="img"><img src="@/assets/images/newIn/sx.png" /></div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="newIn-main-list">
                    <div class="main-list-item" v-loading="loadRight">
                        <div class="list">
                            <!-- <div class="item" v-for="(item, index) in sortProductList" :key="index">
                                <div class="item-img">
                                    <div class="img" @click="detailsClick(item.sku_id)" @mouseover="item.imgHover = true;" @mouseleave="item.imgHover = false;">
                                        <router-link :to="imgTo" @contextmenu.native="mouseRight(item.sku_id)">
                                            <el-image v-show="!item.imgHover" :lazy-src="item.goods_image[0]" :src="item.goods_image[0]" fit="cover" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                                            <el-image v-show="item.imgHover" :lazy-src="item.goods_image[1]" :src="item.goods_image[1]" fit="cover" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                                        </router-link>
                                    </div>
                                    <span v-if="item.discount_price - item.market_price < 0">-{{ (((item.market_price - item.discount_price) / item.market_price) * 100).toFixed(0)  }}%</span>
                                    <button @click.stop="bagShopClick(item.sku_id)">ADD TO BAG</button>
                                </div>
                                <div class="collect">
                                    <span>{{ item.sku_name }}</span>
                                    <img v-if="item.is_collect == 0 || item.is_collect == undefined" src="@/assets/images/newIn/collect.png" style="cursor: pointer" @click="collectClick(item)" />
                                    <img v-if="item.is_collect == 1" src="@/assets/images/newIn/collect1.png" style="cursor: pointer" @click="collectClick(item)" />
                                </div>
                                <div class="price">
                                    <h5 :style="item.discount_price - item.market_price < 0 ? 'color: #FF5656' : 'color: #330000'">{{countrySoyego}}{{strCurXSoyego}}{{ item.discount_price }}</h5>
                                    <s v-if="item.discount_price - item.market_price < 0">{{countrySoyego}}{{strCurXSoyego}}{{ item.market_price }}</s>
                                </div>
                                <div class="item-color">
                                    <div v-if="item.goods_spec_format != null && item.goods_spec_format[0].spec_name == 'Color' && item.goods_spec_format[0].value.length > 1">
                                        <div @click="item.goods_spec_format[0].colorSoyego = item1.sku_id" v-for="(item1, index1) in item.goods_spec_format[0].value" :key="index1" :class="item.goods_spec_format[0].colorSoyego == item1.sku_id ? 'outer-round round-ative' : 'outer-round'">
                                            <div class="round">
                                                <el-image :src="item1.image" fit="cover" style="cursor: pointer;width: 100%;height: 100%;border-radius: 50%;"></el-image>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else></div>
                                </div>
                            </div> -->
                            <div class="item" v-for="(item, index) in sortProductList" :key="index">
                                <div class="item-img">
                                    <div class="img" @click="detailsClick(item.sku_id)" @mouseover="item.imgHover = true;" @mouseleave="item.imgHover = false;">
                                        <router-link :to="imgTo" @contextmenu.native="mouseRight(item.sku_id)">
                                            <el-image v-show="!item.imgHover" :lazy-src="item.goods_image[0]" :src="item.goods_image[0]" fit="cover" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                                            <el-image v-show="item.imgHover" :lazy-src="item.goods_image[1]" :src="item.goods_image[1]" fit="cover" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                                        </router-link>
                                    </div>
                                    <span v-if="item.discount_price - item.cost_price < 0">-{{ (((item.cost_price - item.discount_price) / item.cost_price) * 100).toFixed(0)  }}%</span>
                                    <button @click.stop="bagShopClick(item.sku_id)">ADD TO BAG</button>
                                </div>
                                <div class="collect">
                                    <span>{{ item.goods_name }}</span>
                                    <img v-if="item.is_collect == 0 || item.is_collect == undefined" src="@/assets/images/newIn/collect.png" style="cursor: pointer" @click="collectClick(item)" />
                                    <img v-if="item.is_collect == 1" src="@/assets/images/newIn/collect1.png" style="cursor: pointer" @click="collectClick(item)" />
                                </div>
                                <div class="price">
                                    <h5 :style="item.discount_price - item.cost_price < 0 ? 'color: #FF5656' : 'color: #330000'">{{countrySoyego}}{{strCurXSoyego}}{{ item.discount_price }}</h5>
                                    <s v-if="item.discount_price - item.cost_price < 0">{{countrySoyego}}{{strCurXSoyego}}{{ item.cost_price }}</s>
                                </div>
                                <div class="item-color">
                                    <div v-if="item.goods_spec_format != null && item.goods_spec_format[0].spec_name == 'Color' && item.goods_spec_format[0].value.length > 1">
                                        <div @click="item.goods_spec_format[0].colorSoyego = item1.sku_id" v-for="(item1, index1) in item.goods_spec_format[0].value" :key="index1" :class="item.goods_spec_format[0].colorSoyego == item1.sku_id ? 'outer-round round-ative' : 'outer-round'">
                                            <div class="round">
                                                <el-image :src="item1.image" fit="cover" style="cursor: pointer;width: 100%;height: 100%;border-radius: 50%;"></el-image>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="40" layout="prev, pager, next" :total="total"></el-pagination>
                </div>
                <div class="newIn-main-like" v-loading="loadLikeing">
                    <h5>You may also like</h5>
                    <div class="newIn-main-swiper">
                        <div class="list">
                            <div class="item" v-for="(item, index) in newInList" :key="index">
                                <div class="img" style="width: 100%;height: 390px;" @click="detailsClick(item.sku_id)">
                                    <!-- contain -->
                                    <el-image :src="item.goods_image[0]" fit="cover" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                                </div>
                                <span>{{ item.goods_name }}</span>
                                <p>{{ strCurXSoyego }}{{ item.discount_price }}</p>
                            </div>
                        </div>
                        <div class="btn" @click="getGoodsLike(true)">
                            <button>View More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <el-drawer :visible.sync="drawer" :divection="divection" :before-close="handleClose" class="drawerRight">
            <h5>Filter</h5>
            <div class="drawer-main">
                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item title="Sort by" name="1">
                        <div class="sortText">
                            <div :class="sortText == 0 ? 'ative' : ''" @click="sortText = 0">
                                <span>Recommended</span>
                                <div v-if="sortText == 0" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                            <div :class="sortText == 1 ? 'ative' : ''" @click="sortText = 1">
                                <span>Most Popular</span>
                                <div v-if="sortText == 1" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                            <div :class="sortText == 2 ? 'ative' : ''" @click="sortText = 2">
                                <span>New Arrivals</span>
                                <div v-if="sortText == 2" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                            <div :class="sortText == 3 ? 'ative' : ''" @click="sortText = 3">
                                <span>Top Reviewed</span>
                                <div v-if="sortText == 3" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                            <div :class="sortText == 4 ? 'ative' : ''" @click="sortText = 4">
                                <span>Price Low To High</span>
                                <div v-if="sortText == 4" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                            <div :class="sortText == 5 ? 'ative' : ''" @click="sortText = 5">
                                <span>Price High To Low</span>
                                <div v-if="sortText == 5" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Size" name="2">
                        <div class="sizeText">
                            <div :class="sizeText.includes(item) ? 'ative' : ''" v-for="(item, index) in sizeTextList" :key="index" @click="sizeChange(item)">
                                <span>{{ item }}</span>
                                <div v-if="sizeText.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Color" name="3">
                        <div class="colorText">
                            <div v-for="(item, index) in colorTextList" :key="index" @click="colorChange(item)">
                                <div class="color">
                                    <div class="outer-round">
                                        <div :class="'round round-' + item"></div>
                                    </div>
                                    <span>{{ item }}</span>
                                </div>
                                <div v-if="colorList.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Length" name="4">
                        <div class="sizeText">
                            <div :class="lengthText.includes(item) ? 'ative' : ''" v-for="(item, index) in lengthTextList" :key="index" @click="lengthChange(item)">
                                <span>{{ item }}</span>
                                <div v-if="lengthText.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Sleeve Length" name="5">
                        <div class="sizeText">
                            <div :class="sleeveLengthText.includes(item) ? 'ative' : ''" v-for="(item, index) in sleeveLengthTextList" :key="index" @click="sleeveLengthChange(item)">
                                <span>{{ item }}</span>
                                <div v-if="sleeveLengthText.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Neckline" name="6">
                        <div class="sizeText">
                            <div :class="neckLineText.includes(item) ? 'ative' : ''" v-for="(item, index) in neckLineTextList" :key="index" @click="neckLineChange(item)">
                                <span>{{ item }}</span>
                                <div v-if="neckLineText.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Material" name="7">
                        <div class="sizeText">
                            <div :class="materialText.includes(item) ? 'ative' : ''" v-for="(item, index) in materialTextList" :key="index" @click="materialChange(item)">
                                <span>{{ item }}</span>
                                <div v-if="materialText.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Pattern Type" name="8">
                        <div class="sizeText">
                            <div :class="patternTypeText.includes(item) ? 'ative' : ''" v-for="(item, index) in patternTypeTextList" :key="index" @click="patternTypeChange(item)">
                                <span>{{ item }}</span>
                                <div v-if="patternTypeText.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Style" name="9">
                        <div class="sizeText">
                            <div :class="styleText.includes(item) ? 'ative' : ''" v-for="(item, index) in styleTextList" :key="index" @click="styleChange(item)">
                                <span>{{ item }}</span>
                                <div v-if="styleText.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Sleeve Type" name="10">
                        <div class="sizeText">
                            <div :class="sleeveTypeText.includes(item) ? 'ative' : ''" v-for="(item, index) in sleeveTypeTextList" :key="index" @click="sleeveTypeChange(item)">
                                <span>{{ item }}</span>
                                <div v-if="sleeveTypeText.includes(item)" class="img"><img src="@/assets/images/newIn/√.png" /></div>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Price Range(USD)" name="11">
                        <div class="priceText">
                            <p>{{strCurXSoyego + priceValue[0] + ' - ' + strCurXSoyego + priceValue[1]}}</p>
                            <el-slider v-model="priceValue" range show-stops :max="1000">
                            </el-slider>
                        </div>
                    </el-collapse-item>
                </el-collapse>
                <div class="drawer-btn">
                    <p>{{total}} Items</p>
                    <div class="btn">
                        <button @click="clearClick()">Clear All</button>
                        <button @click="screenClick()">Done</button>
                    </div>
                </div>
            </div>
        </el-drawer> -->

        <el-dialog :visible.sync="bagShop" @closed="handleClose1" top="10vh" width="55vw">
            <BagShop :ids="ids" :num="1" @close="handleClose1"></BagShop>
        </el-dialog>
    </div>
</template>

<style>
.newIn .el-collapse{
    border: none;
}
.newIn .el-collapse-item__wrap{
    border: none;
}
.newIn .el-collapse-item__header{
    border: none;
}
.newIn .el-slider__stop{
    background-color: #ECEEEE;
}
.newIn .el-drawer.ltr, .newIn .el-drawer.rtl, .newIn .el-drawer__container{
    overflow-y: auto;
}
.newIn .el-collapse-item__header {
    font-size: 18px;
    font-family: Montserrat,SF-UI;
    font-weight: bold;
    color: #330000;
}
.newIn .Category .el-collapse-item__header {
    font-size: 16px;
    font-family: Montserrat,SF-UI;
    font-weight: 500;
    color: #330000;
}
.newIn .el-drawer__close-btn {
    outline: none;
}
.newIn .el-drawer__open .el-drawer.rtl {
    outline: none;
}
.newIn .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #330000;
    color: #fff;
}
.newIn .el-pagination.is-background .el-pager li:not(.disabled):hover {
    background: #330000;
    color: #fff;
}
.newIn .el-pagination.is-background .btn-prev,
.newIn .el-pagination.is-background .btn-next,
.newIn .el-pagination.is-background .el-pager li {
    background: transparent;
    color: #330000;
}
.newIn .newIn-main .el-loading-mask {
    background-color: rgba(247, 249, 250, 0.7);
}
.newIn .newIn-main .el-loading-spinner {
    top: 250px;
}
.about-content {
    background: #f7f9fa;
}
</style>

<style lang="scss" scoped>
@import "./dailyUpdate/dailyUpdate.scss";
</style>

<script>
import sale from "./dailyUpdate/dailyUpdate.js"
import BagShop from "@/views/shop/components/bagShop.vue"

export default {
    name: "dailyUpdate",
    components: {
        BagShop
    },
    mixins: [sale]
}
</script>